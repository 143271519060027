<template>
  <div class="col-lg-3 center">
    <div class="card ">
      <div class="card-body text-center">
        <h3 class="mt-3 mb-0">{{chapter.name}}</h3>
        <div class="text-left mt-3">
          <div class="table-responsive">
            <table class="table table-borderless table-sm">
              <tbody>
              <tr>
                <th scope="row">Lecture Name :</th>
                <td class="text-muted" v-if="chapter.name">{{ chapter.name }}</td>
              </tr>
              <tr>
                <th scope="row">Stage :</th>
                <td class="text-muted" v-if="chapter.educational_year">
                  {{ chapter.educational_year.number + ' - ' + chapter.educational_year.description}}
                </td>
              </tr>
              <tr>
                <th scope="row">Price :</th>
                <td class="text-muted" v-if="chapter.price">
                  {{ chapter.price }}
                </td>
                <td class="text-muted" v-else>
                  0
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ChapterSidebar',
    props: ['chapter']
  }
</script>

<style scoped>

</style>
