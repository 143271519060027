<script>
import errorHandler from './../../helpers/errorHandler';
import { required, helpers } from "vuelidate/lib/validators";
let phoneNumberRegex = helpers.regex('phoneNumberRegex',/^01[0-2,5]{1}[0-9]{8}$/)

/**
 * Starter component
 */
export default {
  data() {
    return {
      
      qrCode: "",
      phone: null,
      submitted: false,

    };
  },
  methods:{
    tryToLink() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        this.$store.dispatch("session/phoneValidator", {phone: '2' + this.phone}).then( token =>{
            this.$emit('qrtext',token.qr_code)
            }).catch((error) => {
            errorHandler.methods.error(error);
        });
      }
    },
  },
  validations: {
    phone: {
      required,
      phoneNumberRegex
    },
  },
};
</script>

<template>
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card">
          <div class="card-body p-4">
            <form action="#" @submit.prevent="tryToLink">
              <div class="form-group mb-3">
                <label for="Phoneid">Phone</label>
                <input
                  class="form-control"
                  v-model="phone"
                  type="text"
                  id="phoneid"
                  placeholder="Enter student phone number"
                  :class="{ 'is-invalid': submitted && $v.phone.$error }"
                />
                <div
                  v-if="submitted && $v.phone.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.phone.required">Phone is required.</span>
                </div>
                <div
                  v-if="submitted && $v.phone.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.phone.phoneNumberRegex">Please enter a phone.</span>
                </div>
              </div>
              <div class="form-group mb-0 text-center">
                <button class="btn btn-primary btn-block" type="submit">
                  Validate
                </button>
              </div>
            </form>

           
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
        <!-- end row -->
      </div>
      <!-- end col -->
    </div>
</template>
