<script>
import errorHandler from '../../helpers/errorHandler';
import { required } from "vuelidate/lib/validators";

/**
 * Starter component
 */
export default {
  data() {
    return {
      
      qrCode: "",
      code: null,
      submitted: false,

    };
  },
  methods:{
    tryToLink() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        this.$store.dispatch("session/codeValidator", this.code).then( token =>{
            this.$emit('qrtext',token.qr_code)
            this.$emit('stud_id',this.code)
            }).catch((error) => {
              if(error.response.data.detail["error message"]){
                errorHandler.methods.error(error)
              }else{
                errorHandler.methods.errorMessage(error)
              }
        });
      }
    },
  },
  validations: {
    code: {
      required,
    },
  },
};
</script>

<template>
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card">
          <div class="card-body p-4">
            <form action="#" @submit.prevent="tryToLink">
              <div class="form-group mb-3">
                <label for="Codeid">Student ID</label>
                <input
                  class="form-control"
                  v-model="code"
                  type="text"
                  id="codeid"
                  placeholder="Enter student ID"
                  :class="{ 'is-invalid': submitted && $v.code.$error }"
                />
                <div
                  v-if="submitted && $v.code.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.code.required">Student ID is required.</span>
                </div>
              </div>
              <div class="form-group mb-0 text-center">
                <button class="btn btn-primary btn-block" type="submit">
                  Validate
                </button>
              </div>
            </form>

           
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
        <!-- end row -->
      </div>
      <!-- end col -->
    </div>
</template>
